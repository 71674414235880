.about-section {
  width: 100%;
  background-color: #191a25;
  padding-bottom: 108px;
  // margin-bottom: 100px;

  .about-section-header {
    padding-top: 108px;
  }
  .tiny-title {
    color: #3d7bfa;
    font-family: 'Berlin Sans FB Regular';
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 13px;
    text-align: center;
    margin-bottom: 20px;
  }

  .big-title {
    color: #ffffff;
    font-family: 'Berlin Sans FB Regular';
    font-size: 50px;
    letter-spacing: 0;
    line-height: 55px;
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: 724px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .text-title {
    color: #ffffff;
    font-family: 'Lato';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    // padding: 0 280px;
    @media (max-width: 1039px) {
      padding: 0 40px;
    }
  }

  .text-title-container {
    max-width: 880px;
    margin: 0 auto;
  }

  .buttons-section {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 588px) {
      flex-direction: column;
    }
    a:nth-child(1) {
      margin-right: 7.25px;
      @media (max-width: 588px) {
        margin: 0 auto;
      }
    }
    a:nth-child(2) {
      margin-left: 7.25px;
      @media (max-width: 588px) {
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;
        margin-bottom: 0;
      }
    }
  }

  .quotes-container {
    position: relative;
    padding: 0 210px;
    @media (max-width: 1039px) {
      padding: 0 40px;
    }
    @media (max-width: 380px) {
      padding: 0 5px;
    }
    .quotes-frame {
      position: relative;
      border-radius: 10px;
      background-color: #28293d;
      box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.2);
      border-top: 5px solid #3d7bfa;
      height: auto;
      .quote {
        padding: 41px 70px 36px 70px;
        q {
          color: #ffffff;
          font-family: 'Lato';
          font-size: 18px;
          letter-spacing: 0;
          line-height: 27px;

          * {
            display: inline;
          }
        }
        .quote-author {
          margin-top: 43px;
          // padding: 0 70px;
          .author {
            color: #ffffff;
            font-family: 'Lato';
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 27px;
          }
          .company {
            color: #9090a7;
            font-family: 'Lato';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 27px;
          }
        }
      }
    }
  }

  .about-section-stats {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 93px;
    margin-bottom: 93px;
    @media (max-width: 980px) {
      transform: scale(0.8);
    }
    @media (max-width: 980px) {
      transform: scale(0.8);
    }
    @media (max-width: 670px) {
      transform: scale(0.7);
    }
    @media (max-width: 588px) {
      transform: scale(1);
    }
    @media (max-width: 588px) {
      flex-direction: column;
      margin-top: 30px;
    }

    .stat {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: 70px;
      padding-right: 70px;
      @media (max-width: 588px) {
        margin-top: 40px;
      }
      &:after {
        content: '';
        border-right: 1px solid #9090a7;
        height: 140px;
        position: absolute;
        right: 0;
        @media (max-width: 588px) {
          display: none;
        }
      }
      &:nth-child(1) {
        padding-right: 70px;
        padding-left: 0;
        @media (max-width: 588px) {
          padding: 0 70px;
        }
      }
      &:last-child {
        padding-right: 0;
        padding-left: 70px;
        @media (max-width: 588px) {
          padding: 0 70px;
        }
        &:after {
          content: '';
          border-right: none;
          @media (max-width: 588px) {
            display: none;
          }
        }
      }
      h3 {
        color: #ffffff;
        font-family: 'Berlin Sans FB Regular';
        font-size: 50px;
        letter-spacing: 0;
        line-height: 55px;
        text-align: center;
      }

      span {
        color: #ffffff;
        font-family: 'Berlin Sans FB Regular';
        font-size: 20px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .quotes-controls {
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 0 20px;
    top: 0%;
  }

  #quote-slider-controls {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    top: 45%;
    .prev,
    .next {
      #prev-yes,
      #prev-no,
      #next-yes,
      #next-no {
        display: none;
      }
      #next-no,
      #prev-no {
        cursor: default;
      }
      #next-yes,
      #prev-yes {
        cursor: pointer;
      }
    }
  }
  #quote-slider-nav {
    position: absolute;
    right: 20px;
    bottom: 44px;
    display: flex;
    gap: 12px;
    justify-content: end;
    .quote-slider-dot {
      cursor: pointer;
      svg {
        color: rgba(196, 196, 196, 0.61);
      }
    }
    .tns-nav-active {
      cursor: pointer;
      svg {
        color: #ffffff;
      }
    }
  }

  .active-control-quote-slider {
    display: initial !important;
  }
}
